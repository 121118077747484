import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "../../ngrx/app.reducer";
import { Course } from "../../models/course.model";
import { getS3Resource } from "../../shared/auxiliar";
import { CourseService } from "../../services/course.service";
import { first } from "rxjs/operators";
import { CarouselService } from "src/app/services/carousel.service";
import { Carousel } from "src/app/models/carousel.model";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { NotificationsService } from "src/app/services/notifications.service";
import { SetUserLoggedNotifications } from "src/app/ngrx/user-logged/user-logged.actions";

@Component({
    selector: "app-main",
    templateUrl: "./main.page.html",
    styleUrls: ["./main.page.scss"],
})
export class MainPage implements OnInit {
    liveCoursesEntities: Course[];
    newCoursesEntities: Course[];
    userCoursesEntities: Course[];
    popularCoursesEntities: Course[];
    carousels: Array<any> = [];
    showUserCourses = false;
    showLiveCourses = false;
    showPopularCourses = false;
    warning_version: boolean = false;

    constructor(
        protected http: HttpClient,
        private store: Store<AppState>,
        private courseService: CourseService,
        private carouselService: CarouselService,
        private notificationService: NotificationsService,
    ) {}

    ngOnInit() {
        this.getStore();
        this.getVersion();
    }

    async getVersion() {
        const app_version = environment.app_version;
        this.http.get(`${environment.urlRoot}/version`).subscribe((data: any) => {
            if (data && app_version !== data) {
                this.warning_version = true;
            }
        });
    }

    updateVersion() {
        window.location.reload();
    }

    private getStore() {
        this.courseService
            .getFilterCourse("live")
            .pipe(first())
            .subscribe((e) => (this.liveCoursesEntities = e));

        this.courseService
            .getFilterCourse("new")
            .pipe(first())
            .subscribe((e) => (this.newCoursesEntities = e.length > 9 ? e.slice(0, 9) : e));

        this.courseService
            .getFilterCourse("user")
            .pipe(first())
            .subscribe((e) => {
                this.userCoursesEntities = e;
                this.showUserCourses = e.length > 0;
            });

        this.courseService
            .getFilterCourse("popular")
            .pipe(first())
            .subscribe((e) => {
                this.popularCoursesEntities = e;
                this.showPopularCourses = e.length > 3;
            });

        this.carouselService
            .getAll()
            .pipe(first())
            .subscribe((carousels) => {
                carousels.map((carousel: Carousel) => {
                    this.courseService
                        .getCarouselCourses(carousel.id)
                        .pipe(first())
                        .subscribe((e) => {
                            carousel.courses = e;
                            this.carousels.push(carousel);
                        });
                });
            });
        this.notificationService.getNotifications();
    }

    getImage(course: Course) {
        if (course.imagePath) {
            return getS3Resource(course.imagePath);
        }
    }
}
