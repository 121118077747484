import { Component, OnInit } from "@angular/core";
import { Section } from "src/app/models/section.model";
import { Subscription } from "rxjs";
import { first } from 'rxjs/operators';
import { Video } from "src/app/models/video.model";
import { ActivatedRoute } from "@angular/router";
import { AppState } from "src/app/ngrx/app.reducer";
import { Store } from "@ngrx/store";
import { SectionService } from "src/app/services/section.service";
import { VideoService } from "src/app/services/video.service";
import { getS3Resource } from "src/app/shared/auxiliar";
import { NavController } from "@ionic/angular";
import { ModalController } from '@ionic/angular';
import { DeleteConfirmPage } from "src/app/shared/components/delete-confirm/delete-confirm.page";

@Component({
    selector: "app-course-video-list",
    templateUrl: "./course-video-list.page.html",
    styleUrls: ["./course-video-list.page.scss"],
})
export class CourseVideoListPage implements OnInit {
    section: Section;

    subscription: Subscription = new Subscription();

    selectedVideo: Video;

    hiddenOrder = true;

    constructor(
        private route: ActivatedRoute,
        private nav: NavController,
        private store: Store<AppState>,
        private service: SectionService,
        protected videoService: VideoService,
        public modalController?: ModalController
    ) { }

    ngOnInit() {
        const id = this.route.snapshot.params.id;
        this.getSection(id);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    getSection(id) {
        this.subscription = this.service
            .get(id)
            .subscribe((s: Section) => (this.section = s));
    }

    getImage(video: Video) {
        if (video.imagePath) {
            return getS3Resource(video.imagePath);
        }
    }

    selectVideo(video: Video) {
        this.nav.navigateRoot(["/admin/videos/show/" + video.id]);
    }

    reorderItems(event) {
        const lastOrderArr = JSON.parse(JSON.stringify(this.section.videos));
        const itemMove = this.section.videos.splice(event.detail.from, 1)[0];
        this.section.videos.splice(event.detail.to, 0, itemMove);
        event.detail.complete();

        this.videoService
            .reOrder(lastOrderArr, this.section.videos)
            .subscribe(console.log);
    }

    load() {
        this.nav.navigateRoot(["/admin/courses/list"]);
    }

    async delete(item) {
        const modal = await this.modalController.create({
            component: DeleteConfirmPage,
            cssClass: 'delete-modal'
        });

        modal.onDidDismiss()
            .then((resp) => {
                if (resp.data === true) {
                    this.service.delete(item.id)
                        .pipe(first())
                        .subscribe(() => {
                            this.load();
                        });
                }
            });

        return await modal.present();
    }
}
